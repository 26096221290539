body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --month-height: 300vh;
}

.parent {
  background-color: transparent;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* position: absolute; */
  position: relative;
}

.center {
  width: 0px; /* Or zero, or something very small */
  overflow: visible;
  /* background: red;  */
  margin-top: 10vh;
  position: absolute;
  left: 50vw;
  top: 50%;
  transform: translateY(-50%);
  /* z-index: 100; */
  /* mix-blend-mode: exclusion; */
  

}



.month {
  width: 100vw;
  height: var(--month-height);
  position: relative;
  

}

.month-label {
  font-size: 4vw;
  position: sticky;
  top: 10vh;
  text-align: left;
  margin-left: 5vw;
  z-index: 200;
  color: white;
  mix-blend-mode: exclusion;
}

.timeline {
  height: calc(7 * var(--month-height) - 50vh);
  top: 50vh;
  width: 2vw;
  background-color: white;
  position: absolute;
  mix-blend-mode: exclusion;
}

.picture {
  width: 30vw;
}

.dot {
  height: 60px;
  width: 60px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -10px;
  left: 1vw;

  transform: translateX(-50%);
}

.imgContainer { 
  display: inline-block;
  position: relative;
}

.imgContainer::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  mix-blend-mode: exclusion;
  border-radius: 20px;
}

img {
  z-index: 2;
  position: absolute;
}

.connecting::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
  mix-blend-mode: exclusion;
}

.intro {
  mix-blend-mode: exclusion;
  color: white;
  padding: 40px;
  padding-left: 120px;
  padding-bottom: 0;
}

.master {
  background-color: blue;
  -webkit-transition: background-color 2s; /* For Safari 3.1 to 6.0 */
  transition: background-color 2s;
}



